@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

.titulo-contacto{
    color: white !important;
    font-family: 'DM Sans', sans-serif;
    font-size: 50px;
    width: 50px;
    height: 50px;
    /* centrar vertical y horizontalmente */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
}
.titulo-funcion{
    color:white !important;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(3em + 1vw + 1vh)

}

.back-contacto{
    max-height:100vw;
    min-height: 50vh;
}
.titulo-configurar{
    color: white !important;
    font-family: 'DM Sans', sans-serif;
    font-size: 50px;
    text-align: center;
    position: absolute;
    overflow:hidden;
    left: 28%;
    top: 90px;
}
.titulo-plan{
    color: white !important;
    font-family: 'DM Sans', sans-serif;
    font-size: 50px;
    text-align: center;
    position: absolute;
    overflow:hidden;
    left: 35%;
    margin-left:10px;
    top: 90px; 
}
.back-plan{
    max-height:auto;
    max-height:auto;
    min-height: 400px;
    min-width: 400px;
}
.verde{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgb(17, 134, 66) !important;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
}
td{
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)  
}
.bg{
    background-color:#d9f3e3;
}
.azul{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgb(8, 91, 129) !important;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
}
.rojo{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(255,0,0) !important;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
}
.card-text-subtitulo{
    color: rgb(36, 40, 44);
    font-family: 'DM Sans', sans-serif; 
    font-weight: bold;
    font-size: calc(0.3em + 1vw + 1vh)
}
.card-text-subtitulo2{
    color: rgb(36, 40, 44);
    font-family: 'DM Sans', sans-serif; 
    font-weight: bold;
    text-align: center;
    font-size: calc(0.3em + 1vw + 1vh)
}


.respon{
    overflow-x:auto;
}

table th,
table td .center{
  padding: .625em;
  text-align: center;
  border-right: 1px solid rgb(100,100,100) !important;
}

.center{
    padding: .625em;
    text-align: center;
    font-size: calc(0.3em + 1vw + 1vh);
  }
.linea{
    border-right: 1.5px solid rgb(187, 186, 186) !important;
}
.n-contacto{
    min-height: 250px;   
}
.img-responsive-interfaz{
    height: 100%;
    max-height:100%;
    max-width: 100%;
    vertical-align: middle;
}
