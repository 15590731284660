@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

.parrafo1{
    color: rgb(100, 100, 100);
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.2em + 1vw + 1vh); 
}
.pie{
    color: rgb(100, 100, 100);
    font-family: 'DM Sans', sans-serif; 
    font-size: calc(0.2em + 1vw + 1vh); 

}
.atti{
    color: rgb(100, 100, 100);
    font-family: 'DM Sans', sans-serif; 
    font-size: calc(0.2em + 1vw + 1vh); 
}
.facebook{
   color: rgb(100, 100, 100)!important;
   font-size: calc(1em + 1vw + 1vh);
}
.messenger{
    color: rgb(100, 100, 100)!important;
    font-size: calc(1em + 1vw + 1vh);
}
.instagram{
    color: rgb(100, 100, 100)!important;
    font-size: calc(1em + 1vw + 1vh);
}
.titulof{
    color: white;
    font-family: 'DM Sans', sans-serif;  
    font-size: calc(0.2em + 1vw + 1vh);
}
.whatsapp{
    color: rgb(100, 100, 100)!important;
    font-size: calc(1em + 1vw + 1vh);  
}