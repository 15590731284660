@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
.nav-link{
    color:  rgb(36, 40, 44) !important;
    font-family: 'DM Sans Bold', sans-serif;
    font-weight: bold;
    font-size: calc(0.5em + 1vw)
}

.nav-link:hover{
    color: rgb(46,123,233) !important;
}
.i-active{
    color: rgb(46,123,233) !important;
}
.cart-length{
    margin: 0px;
    font-size: calc(0.1em + 1vw);
    font-weight: 700;
    vertical-align: super;
    font-family: 'Inter-Regular',sans-serif;
    
    }
.cart{
    font-size: calc(0.5em + 1vw)
    }
.boton-probar{
    height: auto;
    max-height:12vw;
    max-width: 12vw;
    vertical-align: middle;
}