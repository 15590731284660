@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
.f-sinborde{
    border: white !important;
    background-size: contain;
    width: 100%;
    background-size: 100% 100%;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.c-general{
    border: none !important;
    background: none;
    overflow:hidden;
}
.card-title-implementa{
    font-size: calc(2em + 1vw);
    color: rgb(225, 126, 0);
    font-family: 'DM Sans-Bold', sans-serif;
    font-weight: bold;
}
.back-fondo-implementa2{
    min-height: 950px;
    min-width: 80px;
}

.card-title-dudas{
    color: rgb(225, 126, 0);
    font-family: 'DM Sans-Bold', sans-serif;
    font-weight: bold;
    font-size: calc(1.7em + 1vw + 1vh);
}
.card-text{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    text-align: justify;
    font-size: calc(0.2em + 1vw + 1vh)
}
.img-responsive-boton{
    height: auto;
    max-height:20vw;
    max-width: 20vw;
    vertical-align: middle;

}
.img-responsive-boton2{
    display:block;
    margin:auto;
    height: auto;
    max-height: 15vw;
    max-width: 15vw;
}
.responsive-boton2{
    display:block;
    margin:auto;
    height: auto;
    max-height:12vw;
    max-width:12vw;
}
.img-responsive-boton3{
    margin:auto;
    height: auto;
    max-height: 15vw;
    max-width: 15vw;
}
.img-responsive-boton4{
    margin:auto;
    height: auto;
    max-height: 20vw;
    max-width: 20vw;
}
.img-responsive-emonedero{
    height: auto;
    max-height:30vw;
    max-width: 30vw;
    vertical-align: middle;
}
.logo-emonedero{
    height: auto;
    max-height:20vw;
    max-width: 20vw;
    vertical-align: middle;
}
.img-responsive-implementa2{
    height: auto;
    max-height:45vw;
    max-width: 45vw;
    vertical-align: middle;
}
.card-text2{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;

    font-size: 22px; 
}
.card-title-implementa2{
    font-size: 60px;
    color: rgb(225, 126, 0);
    font-family: 'DM Sans-Bold', sans-serif;
    font-weight: bold;
}
.card-text-plan{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    font-size: calc(0.1em + 1vw + 1vh);
}
.text-plan{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    text-align: left;
    font-size: calc(0.1em + 1vw + 1vh);  
}
.card-text-plan1{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    font-size: calc(0.2em + 1vw + 1vh);
}
.buscas{
    color: rgb(51,51,51);
    text-decoration: underline;
    font-size: calc(0.1em + 0.5vw + 1vh);
}
.card-text-plan-line{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    text-decoration: underline; 
    font-size: calc(0.2em + 1vw + 1vh);
}
.reclamar{
color: white;
font-family: 'DM Sans', sans-serif;
text-align: center;
text-decoration: underline; 
font-size: calc(0.2em + 1vw + 1vh);
}
.prueba{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    text-decoration: underline; 
    font-size: calc(0.2em + 1vw + 1vh);
}
.titulo-economia{
    color: rgb(225, 126, 0);
    text-align: center;
    font-weight: bold;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.1em + 0.5vw + 1vh);
}
.subtitulo-economia{
    text-align: center;
    align-content: right;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(1.7em + 1vw + 1vh);
}

.clientes{
    color: rgb(46, 123, 233);
    font-family: 'DM Sans', sans-serif;
}

.cardSinborde{
    max-width: auto;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border:none;
}
.titulo-emonedero{
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.1em + 1vw + 1vh);
    color: rgb(36, 40, 44);

}
.tel{
    font-family: 'DM Sans', sans-serif;
    font-size: calc(1em + 1vw + 1vh)
}
.telefono{
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.5em + 1vw + 1vh)

}
.img-economia{
 align-content: center;
 align-items: center;
 text-align: center;
}
.card-text-economia{
    color: rgb(100,100,100);
    text-align: justify;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.2em + 1vw + 1vh)
}

.card-title-ecosistema{
    color: rgb(46, 123, 233);
    font-family: 'DM Sans', sans-serif;
}

.card-title-monedero{
    color: rgb(46, 123, 233);
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    font-size: calc(1.7em + 1vw + 1vh) 
}
.card-title-contacto{
    color: rgb(46, 123, 233);
    font-family: 'DM Sans', sans-serif;
    text-align: left;
    font-size: calc(1.5em + 1vw + 1vh);
}

.titulo-ecosistema{
    text-align: center;
    color: rgb(225, 126, 0);
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.1em + 0.8vw + 1vh); 
    
}
.titulo-formulario{
    text-align: left;
    color: rgb(225, 126, 0);
    font-family: 'DM Sans', sans-serif;
}
.nota{
    color: rgb(51, 51, 51);
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    font-style: oblique;
    font-size: calc(0.1em + 1vw)

}
.aprende{
    text-align: center; 
}
.monedero{
    color: rgb(46, 123, 233); 
    font-weight: bold; 
}
.titulo-sistema{
    color: rgb(225, 126, 0); 
    font-family: 'DM Sans', sans-serif;  
    font-size: calc(0.1em + 0.8vw + 1vh); 

}
.card-title-configurar{
    color: rgb(46, 123, 233); 
    font-family: 'DM Sans', sans-serif;  
    font-size: calc(1.7em + 1vw + 1vh);
}

.titulo-ofrecer{
    color:white;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.1em + 0.6vw + 1vh);
}
.subtitulo-ofrecer{
    color:white;
    text-align: center;
    font-family: 'DM Sans', sans-serif; 
    font-size: calc(1.5em + 1vw + 1vh);
}
.subtitulos-ofrecer{
    color: rgb(46, 233, 204); 
    text-align: center; 
    font-family: 'DM Sans', sans-serif; 
    font-size: calc(0.1em + 0.9vw + 1vh);

}
.card-text-ofrecer{
    color:white;
    text-align: center;
    font-family: 'DM Sans', sans-serif; 
    font-size: calc(0.1em + 0.9vw + 1vh);
}
.img-ofrece{
    height:500px;
    position: absolute;
    overflow:hidden;
    left: 8%;
    margin-left: 0px;
    top: 0px;
}
.fondo{
    max-height: 100%;
    min-width: 100%;


}
.html,body{
    /* Para que funcione correctamente en Smarthpones y Tablets */
    height:100vh;
}
.back-fondo{
    max-height: 1000px;
}

.back-fondo-implementa{
    max-height: auto;
    min-height: auto;
}
.back-fondo2{
    max-height: auto;
    min-height: auto;
}
.back-ofrecer{
    max-height: auto;
    min-height: auto;
}

.cardsinborde{
    border: none !important;
}
.img-center{
display:block;
margin:auto;
height: auto;
max-height:12vw;
max-width: 12vw;
}
.img-center-ofrecer{
display:block;
margin:auto;
height: auto;
max-height:8vw;
max-width: 8vw;
}

.img-center-ofrecer1{
display:block;
margin:auto;
height: auto;
max-height:5vw;
max-width: 5vw;
}
.img-center-ofrecer2{
display:block;
margin:auto;
height: auto;
max-height:15vw;
max-width: 15vw;
}

.img-beneficios{
display:block;
margin:auto;
margin-top: calc(2em + 1vw);;
height:8vw;
width: 8vw;  
}

.img-responsive {
height: auto;
max-height: 700px;
max-width: 100%;
vertical-align: middle;
      }
.img-responsive-bolsillo{
    height: auto;
    max-width: 100%;
    vertical-align: middle;   
}
.responsive-imagen{
    height: auto;
    max-width: 100%;
    vertical-align: middle;  
}
.img-responsive-ecosistema{
height: auto;
max-height:500px;
max-width:500px;
vertical-align: middle;
 }     
.img-responsive-inicio {
height: auto;
max-width: 100%;
vertical-align: middle;
min-width: 650px;
}
.img-responsive-integracion{
    height: auto;
    max-height:auto;
    max-width:auto;
    vertical-align: middle; 
    
}
.img-responsive-implementa{
    height: auto;
    max-height:auto;
    max-width: 100%;
    vertical-align: middle;
}

.tamaño{
    max-width: 100%;
    min-height: 500px;
    max-height: 500px;
    min-width: 650px;
}
      
.lineabeneficio{
   border-width: 3px;
   border: 1px solid black !important;
   height:2px;
   display:block;
   margin:auto;
}
.boton-beneficio{
    text-align: center;
}
.subtitulo-beneficio{
    font-family: 'DM Sans', sans-serif; 
    color: rgb(36, 40, 44);
    text-align: center;
    font-size: calc(0.4em + 0.5vw + 1vh);
}
.titulo-beneficio{
  color: rgb(36, 40, 44);
  font-family: 'DM Sans', sans-serif; 
  font-weight: bold;
  font-size: calc(0.5em + 0.5vw + 1vh)
}
.card-title-planes{
    color: rgb(225, 126, 0); 
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    font-size: calc(1.7em + 1vw + 1vh);
}

.tituloPlan{
    text-align: center;
    color: rgb(36, 40, 44);
    font-size: calc(0.5em + 0.5vw + 1vh);
}
.subtituloPlan{
    text-align: center;
    color: rgb(46, 123, 233);
    font-size: calc(1.7em + 1vw + 1vh);
}
.subtituloPlan2{
    color: white;
    font-family: 'DM Sans', sans-serif;
    text-align: center; 
    font-size: calc(0.5em + 0.5vw + 1vh);
}
.subtituloPlan3{
    color: white;
    font-family: 'DM Sans', sans-serif;
    text-align: center; 
    font-size: calc(1.5em + 1vw + 1vh);   
}
.pagoPlan2{
    color: white;
    font-family: 'DM Sans', sans-serif;
    text-align: center; 

}
.peso2{
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    font-size: calc(1em + 0.9vw + 1vh);
    color:  white;
    text-align: center;
}
.precio{
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    font-size: calc(1em + 0.9vw + 1vh);
    color:  white;
    text-align: center;
}
.card-text-plan2{
    color: white;
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    font-size: calc(0.1em + 1vw + 1vh);
}
.card-text-plan-line2{
    color: white;
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    text-decoration: underline;
    font-size: calc(0.2em + 1vw + 1vh);

}
.subtitulo2{
    font-size: 35px; 
    font-weight: bold;
}

.plan1{
    border-radius: 0.5em;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    max-height: auto;
    min-height: calc(0.5em + 1vw + 1vh);
    width: auto;
    height: 100%;
    
}
.plan2{
    border-radius: 0.5em;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    background-color: rgb(46, 123, 233);
    width: auto;
    height: 100%;
}
.plan3{
    border-radius: 0.5em;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    background-color: rgb(225, 126, 0);
    width: auto;
    height: 100%;
}
.plan4{
    border-radius: 0.5em;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    background-color: rgb(46, 233, 204);
    width: auto;
    height: 100%;
}
.plan5{
    border-radius: 0.5em;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    background-color: rgb(46, 123, 233);
    width: auto;
    height: 100%;
}
.plan6{
    border-radius: 0.5em;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    background-color: rgb(225, 126, 0);
    width: auto;
    height: 100%;
}
.plan7{
    border-radius: 0.5em;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    background-color: rgb(202, 46, 233);
    width: auto;
    height: 100%;
}
.peso{
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    font-size: 46px;
    color:  rgb(65, 63, 63);
    text-align: center;
}
.pagoPlan{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    text-align: center; 
}
.mes{
    color: white;
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    font-size: calc(0.1em + 1vw + 1vh);  
}

.label-center{
    text-align: left;
}
.whatsapp{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgb(100,100,100) !important;
    font-size: 25px;
}

.facebook{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgb(100,100,100) !important;
    font-size: 25px;
}
.messenger{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgb(100,100,100) !important;
    font-size: 25px;
}
.instagram{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgb(100,100,100) !important;
    font-size: 25px; 
}
#nombreCliente{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    border-radius: 20px;
    border:1px solid rgb(100,100,100);
    font-size: calc(0.3em + 1vw + 1vh)
}
#correoCliente{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    border-radius: 20px;
    border:1px solid rgb(100,100,100);
    font-size: calc(0.3em + 1vw + 1vh)
}
#telefonoCliente{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    border-radius: 20px;
    border:1px solid rgb(100,100,100);
    font-size: calc(0.3em + 1vw + 1vh)
}
#asuntoCliente{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    border-radius: 20px;
    border:1px solid rgb(100,100,100);
    font-size: calc(0.3em + 1vw + 1vh)
}
#textarea{
    color: rgb(100,100,100);
    font-family: 'DM Sans', sans-serif;
    border-radius: 20px;
    border:1px solid rgb(100,100,100);
    font-size: calc(0.3em + 1vw + 1vh)
}
.enviar{
    background: none;
    border: none;
}
.titulo-administrador{
    color: rgb(225, 126, 0);
    text-align: center;
    font-weight: bold;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.1em + 0.8vw + 1vh);
}
.subtitulo-administrador{
    text-align: center;
    align-content: center;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(1.7em + 1vw + 1vh);

}
.card-text-bolsillo{
    color: rgb(36,40,44);
    font-family: 'DM Sans', sans-serif; 
}
.subrayado{
    color: rgb(36,40,44)!important;
    background-color:rgb(36,40,44)!important;
    border:2px;
}
.izquierda{
    float: left;
}
.product-add-button{
    border: none;
    background: none;
    display:block;
    align-items: center;
    margin:auto;
}
.product-add-button1{
    border: none;
    background: none;
    display:block;
    margin-top:20%;
    margin-left:50%;
    transform: translateX(-50%);

}
.product-add-button2{
    border: none;
    background: none;
    display:block;
    margin-top:30%;
}
.product-add-button3{
    border: none;
    background: none;
    display:block;
    margin-top:37%;
    margin-left:50%;
    transform: translateX(-50%);
}

.parrafo1{
    color: rgb(100, 100, 100);
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.2em + 1vw + 1vh); 
}
.pie{
    color: rgb(100, 100, 100);
    font-family: 'DM Sans', sans-serif; 
    font-size: calc(0.2em + 1vw + 1vh); 

}
.atti{
    color: rgb(100, 100, 100);
    font-family: 'DM Sans', sans-serif; 
    font-size: calc(0.2em + 1vw + 1vh); 
}
.facebook{
   color: rgb(100, 100, 100)!important;
   font-size: calc(1em + 1vw + 1vh);
}
.messenger{
    color: rgb(100, 100, 100)!important;
    font-size: calc(1em + 1vw + 1vh);
}
.instagram{
    color: rgb(100, 100, 100)!important;
    font-size: calc(1em + 1vw + 1vh);
}
.titulof{
    color: white;
    font-family: 'DM Sans', sans-serif;  
    font-size: calc(0.2em + 1vw + 1vh);
}
.whatsapp{
    color: rgb(100, 100, 100)!important;
    font-size: calc(1em + 1vw + 1vh);  
}
.nav-link{
    color:  rgb(36, 40, 44) !important;
    font-family: 'DM Sans Bold', sans-serif;
    font-weight: bold;
    font-size: calc(0.5em + 1vw)
}

.nav-link:hover{
    color: rgb(46,123,233) !important;
}
.i-active{
    color: rgb(46,123,233) !important;
}
.cart-length{
    margin: 0px;
    font-size: calc(0.1em + 1vw);
    font-weight: 700;
    vertical-align: super;
    font-family: 'Inter-Regular',sans-serif;
    
    }
.cart{
    font-size: calc(0.5em + 1vw)
    }
.boton-probar{
    height: auto;
    max-height:12vw;
    max-width: 12vw;
    vertical-align: middle;
}
.titulo-contacto{
    color: white !important;
    font-family: 'DM Sans', sans-serif;
    font-size: 50px;
    width: 50px;
    height: 50px;
    /* centrar vertical y horizontalmente */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
}
.titulo-funcion{
    color:white !important;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(3em + 1vw + 1vh)

}

.back-contacto{
    max-height:100vw;
    min-height: 50vh;
}
.titulo-configurar{
    color: white !important;
    font-family: 'DM Sans', sans-serif;
    font-size: 50px;
    text-align: center;
    position: absolute;
    overflow:hidden;
    left: 28%;
    top: 90px;
}
.titulo-plan{
    color: white !important;
    font-family: 'DM Sans', sans-serif;
    font-size: 50px;
    text-align: center;
    position: absolute;
    overflow:hidden;
    left: 35%;
    margin-left:10px;
    top: 90px; 
}
.back-plan{
    max-height:auto;
    max-height:auto;
    min-height: 400px;
    min-width: 400px;
}
.verde{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgb(17, 134, 66) !important;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
}
td{
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)  
}
.bg{
    background-color:#d9f3e3;
}
.azul{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgb(8, 91, 129) !important;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
}
.rojo{
    padding: 0 !important;
    border: none !important; 
    background: none !important; 
    color: rgba(255,0,0) !important;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: calc(0.3em + 1vw + 1vh)
}
.card-text-subtitulo{
    color: rgb(36, 40, 44);
    font-family: 'DM Sans', sans-serif; 
    font-weight: bold;
    font-size: calc(0.3em + 1vw + 1vh)
}
.card-text-subtitulo2{
    color: rgb(36, 40, 44);
    font-family: 'DM Sans', sans-serif; 
    font-weight: bold;
    text-align: center;
    font-size: calc(0.3em + 1vw + 1vh)
}


.respon{
    overflow-x:auto;
}

table th,
table td .center{
  padding: .625em;
  text-align: center;
  border-right: 1px solid rgb(100,100,100) !important;
}

.center{
    padding: .625em;
    text-align: center;
    font-size: calc(0.3em + 1vw + 1vh);
  }
.linea{
    border-right: 1.5px solid rgb(187, 186, 186) !important;
}
.n-contacto{
    min-height: 250px;   
}
.img-responsive-interfaz{
    height: 100%;
    max-height:100%;
    max-width: 100%;
    vertical-align: middle;
}

.cart-items{
    margin: 30px auto;
    width: 50%;
    background-color:rgba(244,248,252);
    border: none;
    border-radius: 5px;
    font-family: 'Inter-Regular',sans-serif;
    color:  rgb(128,128,128);
}
.cart-items-price{
    font-size: calc(0.3em + 1vw + 1vh);
    color:  rgb(128,128,128);
}
.cart-items-header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: calc(0.5em + 1vw + 1vh);
    height: 50px;
    border-bottom: gray;
    font-family: 'Inter-Bold',sans-serif;
    color:  rgb(128,128,128);
}
.cart-items-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(0.4em + 1vw + 1vh);
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Inter-Bold',sans-serif;
    color:  rgb(128,128,128);

}

.cart-items-list{
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    margin-left: 5%;
    font-family: 'Inter-Regular',sans-serif;
}
.cart-items-name,
.cart-items-function {
    width: 25%;
    font-size: calc(0.3em + 1vw + 1vh)

}

.cart-items-add,
.cart-items-remove{
    width: calc(0.5em + 1vw + 1vh);
    margin-right: 15px;
    font-weight: 700;
    border: gray;
    border-radius: 3px;
    font-size: calc(0.3em + 1vw + 1vh);
    color: white;
    cursor: pointer;
    font-family: 'Inter-Regular',sans-serif;
    
}
.cart-items-add{
    background-color:red;
}
.cart-items-remove{
    background-color: green;
}

.cart-items-add:active{
    transform: translateY(1.2px);
}
.cart-items-remove:active{
    transform: translateY(1.2px);
}
.cart-items-total-price-name{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 22%;
    font-size: calc(0.3em + 1vw + 1vh);
    font-weight: 700;
    border-top: gray;
    font-family: 'Inter-Regular',sans-serif;
}

.cart-items-total-price{
    display: flex;
    padding-left: 45%;
    font-family: 'Inter-Regular',sans-serif;
    font-size: calc(0.3em + 1vw + 1vh);


}
.clear-cart{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
}

.clear-cart-button{
    border: none;
    border-radius: 5px;
    background-color: #bd0e0e;
    height: calc(1em + 1vw + 1vh);
    font-size: calc(0.3em + 1vw + 1vh);
    color:white;
    cursor: pointer;
}
.clear-cart-button:hover{
    background-color: #0e557e;
}
.paypal{
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: calc(5em + 1vw + 1vh);
}
.monto{
    justify-content: center;
    align-items: center;
    font-size: calc(1em + 0.9vw + 1vh);
    font-family: 'Inter-Regular',sans-serif;
    display: flex; 
}
.paypal{
font-size: calc(3em + 2vw + 1vh);
height: 5vw;

}
@media(max-width: 1030px){
.container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
}
}
